.top-up-balance {
  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    &__empty {
      width: 100%;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 20px;
        text-align: center;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding: 2px;
      background-color: var(--shade1);
      border-radius: 12px;
      cursor: pointer;
      user-select: none;

      &.active {
        border: 1px solid var(--shade3);
      }

      img {
        width: 100%;
        border-radius: 12px;
      }

      span {
        font-weight: 500;
        padding: 4px;
      }
    }
  }
}
