@import "../../style/mixin";

.games__games {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 48px 12px;

  @include l1440 {
    grid-template-columns: repeat(5, 1fr);
  }

  @include l1220 {
    margin-top: 32px;
    grid-template-columns: repeat(6, 1fr);
    gap: 32px 12px;
  }

  @include smallDesktop {
    grid-template-columns: repeat(5, 1fr);
  }

  @include ltb {
    grid-template-columns: repeat(4, 1fr);
  }

  @include l670 {
    margin-top: 20px;
    gap: 16px 8px;
  }

  @include lxs {
    grid-template-columns: repeat(3, 1fr);
  }
}
