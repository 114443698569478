@import "../../../../style/mixin";

.dynamic-banners {
  user-select: none;
  position: relative;

  * {
    user-select: none;
  }

  .owl-dots {
    bottom: 10px;
    li button:before {
      font-size: 12px;
      color: #fff;
    }
  }

  .banner-item__img__bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
  }

  &__carousel {
    cursor: grab;
    user-select: none;
    border-radius: 16px;
    overflow: hidden;

    * {
      user-select: none;
      outline: none;
    }
  }

  @include ltb {
    &__carousel {
      border-radius: 8px;
    }
  }
}
