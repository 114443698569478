@import "../../../style/mixin";

.button-up {
  height: 48px;
  padding: 16px;
  border-radius: 12px;
  background-color: var(--primary-btn);
  display: none;
  align-items: center;
  position: fixed;
  z-index: 11;
  right: 3svw;
  bottom: 10svh;
  gap: 8px;

  &:hover {
    background-color: var(--primary-btn-hover);
  }

  span {
    font-size: 16px;
    font-weight: 700;
  }

  &.shown {
    display: flex;
  }

  svg {
    width: 24px;
    height: auto;
    path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }

  @include l670 {
    bottom: 130px;
  }
}
