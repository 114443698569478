.default-modal.mini .locked-container h2 {
  width: 90%;
  text-align: center;
}

.locked-container {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    margin-top: 12px;
    text-align: center;
    color: var(--red);
  }

  .time-container {
    display: flex;
    gap: 12px;
    margin-top: 20px;

    &__horus,
    &__days,
    &__minutes {
      display: flex;
      align-items: center;
      gap: 4px;

      .name {
        font-size: 20px;
      }

      .value {
        color: var(--red);
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  .warning-info {
    cursor: pointer;
    margin-top: auto;
  }
}
