// b4k - big > 4k
@mixin b4k {
  @media (min-width: 3840px) {
    @content;
  }
}

// b2k - big > 2k
@mixin b2k {
  @media (min-width: 1920px) {
    @content;
  }
}

// b1800 - big > 1800
@mixin b1800 {
  @media (min-width: 1820px) {
    @content;
  }
}

// b1580 - big > 1580
@mixin b1580 {
  @media (min-width: 1580px) {
    @content;
  }
}

// l1580 - less > 1580

@mixin l1580 {
  @media (max-width: 1580px) {
    @content;
  }
}

// l1440 - less < 1440
@mixin l1440 {
  @media ((max-width: 1440px)) {
    @content;
  }
}

// l1220 - less < 1220
@mixin l1220 {
  @media ((max-width: 1220px)) {
    @content;
  }
}

// smallDesktop
@mixin smallDesktop {
  @media ((max-width: 1024px)) {
    @content;
  }
}

// lsm - less small medium
@mixin lsm {
  @media (max-width: 950px) {
    @content;
  }
}

// lt - less tablet
@mixin ltb {
  @media (max-width: 780px) {
    @content;
  }
}

// l670 - less 670
@mixin l670 {
  @media (max-width: 670px) {
    @content;
  }
}

//sm - mobile
@mixin sm {
  @media (max-device-width: 670px) and (max-width: 670px) and (max-aspect-ratio: 13/9) {
    @content;
  }
}

// lxs - less extra small
@mixin lxs {
  @media (max-width: 576px) {
    @content;
  }
}

// llxs - less extra extra small
@mixin llxs {
  @media (max-width: 428px) {
    @content;
  }
}

//smLsp - size mobile and landscape position
@mixin smLsp {
  @media ((max-height: 670px) and  (orientation: landscape)) {
    @content;
  }
}

@mixin mobile {
  @media (((max-device-width: 670px) and (max-width: 670px) and (max-aspect-ratio: 13/9)) or ((max-height: 670px) and  (orientation: landscape))) {
    @content;
  }
}

//mobileSize - size mobile and less 576px
@mixin mobileSize {
  @media (((max-device-width: 670px) and (max-width: 670px) and (max-aspect-ratio: 13/9)) or (max-width: 576px)) {
    @content;
  }
}

//

@mixin columnGap($gap: 12px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}
