%home-content {
  max-width: 1920px;
  padding: 0 16px;
}

%default-item {
  background-color: var(--landing-item-bg);
  border-radius: 12px;
  padding: 8px;
}

%borderLanding {
  border-bottom: 3px solid var(--landing-border);
}

%one-string {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
