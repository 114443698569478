@import "../../../style/template.scss";

.providers {
  &__content {
    .providers__item {
      outline: none;
      border: none;
      background-color: transparent;

      img {
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
      }
    }
  }
}
