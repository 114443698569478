@import "../../../style/mixin";

.type-game-menu {
  display: flex;
  gap: 12px;
  margin: 16px;
  margin-top: 24px;

  .type-game-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    border-radius: 12px;
    background-color: transparent;

    span {
      font-size: 14px;
      font-weight: 700;
      color: var(--landing-text-label-2);
    }

    &:hover {
      span {
        color: var(--white);
      }

      svg path {
        fill: var(--white);
      }
    }

    &.active {
      background-color: var(--primary-btn-2);
      span {
        color: var(--primary-btn-2-text);
      }
    }
  }

  @include l1440 {
    gap: 8px;

    .type-game-item {
      padding: 12px;
      gap: 6px;
      align-items: center;

      svg {
        width: auto;
        height: 14px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  @include lxs {
    margin: 16px 0;

    .type-game {
      &-item {
        // padding: 8px 4px;
        gap: 4px;

        span {
          font-size: 11px;
        }
      }
    }
  }
}
