@import "../../../style/mixin";

%img-and-text {
  padding: 8px;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
}

.top-up-balance {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 32px;

  p {
    color: var(--landing-text-label-2);
    font-size: 14px;
    font-weight: 600;

    span {
      font-weight: 600;
    }
  }

  .top-up {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__real {
      &__form {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    &__min-deposit {
      @extend %img-and-text;
      background-color: var(--warning-check-bg);

      &__text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
      }

      &__value {
        font-size: 12px;
        display: flex;
        gap: 4px;
        color: var(--gray2);
      }

      &__currency {
        font-size: 12px;
        color: var(--jackpot-currency);
      }
    }

    &__buttons {
      display: flex;
      gap: 8px;
      justify-content: space-between;

      button {
        width: 100%;
      }
    }

    &__crypto {
      &.error {
        min-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__warning {
        @extend %img-and-text;
        background-color: var(--red01);

        &__text {
          display: flex;
          gap: 8px;
        }

        &__red span {
          color: var(--pink);
          font-weight: 600;
        }
      }

      &__min-deposit {
        @extend %img-and-text;
        background-color: var(--warning-check-bg);

        &__info {
          display: flex;
          justify-content: space-between;

          .currency {
            span {
              color: var(--gray2);
            }
          }

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  @include llxs {
    .top-up__buttons {
      flex-direction: column;
    }
  }
}
