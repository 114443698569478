@import "../../../../style/mixin";

.banner-item {
  width: 100%;
  position: relative;

  &__content {
    display: flex;
    justify-content: center;
  }

  &__img {
    overflow: hidden;
    border-radius: 16px;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 45svh;
  }

  &__video {
    overflow: hidden;
    border-radius: 16px;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 45svh;
  }
}
