@import "../../../style/mixin";

.carousel-desk {
  margin-top: 20px;

  .slick-slide {
    cursor: grab;
    img {
      cursor: pointer;
    }
  }

  .carousel-plug {
    display: grid;
    gap: 12px;
    &-2 {
      grid-template-columns: 1fr 1fr;
    }
    &-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    &-5 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    &-6 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  .game-desk__items {
    cursor: pointer;
  }

  @include l670 {
    margin-top: 10px;
  }
}
