@import "../../../style/template.scss";
@import "../../../style/mixin";

#root .aside-menu.fixed {
  transition: 0s;
}

.aside-menu {
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 10;

  &__plug {
    box-shadow: none !important;
    height: 52px;
  }

  &.open {
    .btn-arrow {
      svg path {
        fill: var(--green-3);
      }
    }
  }

  .btn-arrow {
    display: none;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: auto;
    height: auto;
    box-shadow: 10px 5px 5px var(--black);
    border-radius: 0 0 16px 16px;

    // &::-webkit-scrollbar {
    //   width: 4px;
    //   background-color: #fff;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: #fff;
    //   border: 3px solid #fff;
    // }
  }

  .bonus-menu {
    border-radius: 16px;
  }

  .logo {
    margin: 4px;
  }

  button {
    z-index: 2;
  }

  .menu-content {
    @extend %default-item;
    background-color: var(--landing-item-bg);

    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .menu__other {
    h2 {
      display: flex;
      gap: 8px;
    }

    & > div {
      margin-top: 6px;
    }

    &__item {
      background-color: transparent;
      outline: none;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 16px;
      border-radius: 8px;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }

      &__title {
        font-size: 14px;
        font-weight: 600;
        color: var(--landing-text-label);
      }
    }
  }

  .widget_container {
    border-radius: 12px;
    height: 100%;
    min-height: 410px;
  }

  .tg-group {
    @extend %default-item;
    background-color: var(--landing-item-bg) !important;
    font-size: 14px;
    font-weight: 600;
    color: var(--telegram);
    padding: 16px;
    text-align: center;
  }

  &.fixed.open {
    position: fixed;
    background-color: var(--landing-background);
    box-shadow: 3px 3px 10px var(--black);
    padding-top: 16px;
    top: 0;

    .aside-menu__menu {
      position: static;
      box-shadow: none;
    }

    @include l670 {
      padding-top: 8px;
    }

    @include lxs {
      .logo {
        justify-content: start;
      }
    }
  }

  @include l1220 {
    box-shadow: 3px 3px 10px var(--black);
    left: 8px;
    top: 8px;

    .btn-arrow {
      display: block;
      padding: 4px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }

    &.open {
      .aside-menu__menu {
        display: flex;
      }
    }

    &__menu {
      background-color: var(--landing-background);
      display: none;
      position: absolute;
      width: 210px;
      top: 72px;
    }

    .logo {
      height: auto;
      padding: 8px 4px;
      justify-content: space-between;

      img {
        height: 28px;
        width: auto;
      }

      &__text {
        font-size: 14px;
      }
    }

    .bonus-menu {
      h2 {
        font-size: 16px;
      }

      img {
        width: 160px;
        bottom: -32%;
        right: -20%;
      }
    }

    .widget_container {
      iframe {
        min-height: 400px;
      }
    }

    .menu__providers {
      margin-top: 8px;
    }

    .cus-select__options__li {
      padding: 8px;
      &__label {
        font-size: 12px;
      }
    }
  }

  @include lxs {
    .btn-arrow {
      width: 36px;
      height: 36px;
    }

    .logo img {
      height: 36px;
    }
  }
}
