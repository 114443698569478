@import "../../../style/template.scss";

.circle-info {
  position: relative;
  cursor: pointer;

  &:hover {
    .circle-info__popup {
      display: block;
    }
  }

  &.touch {
    &.active {
      .circle-info__popup {
        display: block;
      }
    }

    .circle-info__popup {
      display: none;
    }
  }

  &__popup {
    display: none;
    @extend %default-item;
    position: absolute;
    padding: 12px 16px;
    width: 270px;
    left: calc(-135px + 50%);
    bottom: 40px;
    z-index: 10;
    font-size: 14px;
    color: var(--landing-text-label-2);
    font-weight: 500;

    &__rect {
      @extend %default-item;
      border-radius: 0;
      position: absolute;
      left: calc(50% - 10px);
      bottom: -10px;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
    }

    &__plug {
      position: absolute;
      background-color: transparent;
      bottom: -20px;
      width: 100%;
      left: 0;
      height: 20px;
    }
  }
}
