@import "../../../style/mixin";

#root {
  background: var(--landing-background);
}

.page-witch-menu {
  display: grid;
  grid-template-columns: 300px 1fr;
  max-width: 2048px;
  margin: 0 auto;
  margin-top: 0;

  .aside-menu {
    width: 300px;
  }

  #container-widget {
    position: relative;
    height: 100%;
    iframe {
      height: 100%;
      min-width: auto !important;
    }
  }

  & > div {
    padding: 0 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  @include l1220 {
    grid-template-columns: 1fr;

    & > div {
      padding: 0 8px;
    }

    &__aside {
      display: none;
    }

    .aside-menu {
      width: 210px;
    }

    #container-widget {
      overflow: hidden;
      position: relative;
      min-height: 400px;
      height: 100%;

      iframe {
        scale: 0.7;
        transform: translate(-22%, -22%);
        width: auto;
        position: absolute;
        height: calc(100% / 0.7 + 20px);
      }
    }
  }

  @include lxs {
    & > div {
      padding: 0 10px;
    }
  }
}
