@import "../../../style/mixin";

.error-game-modal {
  &__content {
    margin-top: 40px;

    p {
      color: var(--landing-text-label);
      font-size: 16px;
      font-weight: 600;
    }

    .buttons {
      display: flex;
      gap: 8px;

      span {
        font-size: 20px;
      }
    }

    button {
      margin-top: 20px;
      width: 100%;
    }
  }

  @include l670 {
    &__content {
      .buttons {
        span {
          font-size: 12px;
        }
      }
    }
  }
}
