@import "../../../../style/mixin";
@import "../../../../style/template";

.logo {
  @extend %default-item;
  display: flex;
  // align-items: flex-end;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &__img {
    width: 100%;
    max-width: 40px;
    max-height: 40px;
  }

  &__text {
    font-size: 20px;
    line-height: 110%;
    font-weight: 700;
    color: var(--white);
    font-family: "Unbounded";
  }
}
