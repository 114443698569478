@import "../../../style/mixin";
@import "../../../style/template";

// .custom-input {
//   position: relative;
//   &__input {
//     width: 100%;
//     background-color: var(--landing-border-input-bg);
//     border-radius: 8px;
//     padding: 20px;
//     font-size: 20px;
//   }
// }

.custom-input {
  position: relative;
  display: flex;
  flex-direction: column;

  &__input {
    @extend %one-string;
  }

  &.icon-JSX-start {
    .icon-JSX-start__div {
      position: absolute;
      height: 100%;
      left: 20px;
      align-items: center;
      display: flex;

      svg {
        width: 24px;
        height: auto;
      }
    }

    .custom-input__input {
      margin-left: 40px;
      width: calc(100% - 40px);

      &.password,
      &.is-copy {
        width: calc(100% - 72px);
      }
    }
  }

  &__gray-warning {
    font-size: 16px;
    line-height: 24px;
    color: #c9c9c9;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: var(--landing-text-label);
  }

  &__container {
    margin-top: 8px;
    background-color: var(--primary-select-bg);
    border-radius: 16px;
    position: relative;

    &.error {
      border: 2px solid var(--red);
    }

    &.success {
      border: 2px solid var(--green);
    }
  }

  &.error-container {
    & ~ button {
      &:hover {
        cursor: default;
        opacity: 0.6;
      }
      opacity: 0.6;
      filter: contrast(100%);
    }

    .text-error {
      font-size: 18px;
      font-weight: 400;
    }
  }

  input {
    width: 100%;
    padding: 16px;
    background-color: transparent;
    color: var(--white);
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;

    &.is-copy,
    &.password,
    &.icon {
      width: calc(100% - 32px);
    }

    &::placeholder {
      color: var(--landing-placeholder);
    }
  }
  input[type="password"] {
    width: calc(100% - 24px);
  }

  &.icon-end-container {
    .custom-input__input {
      width: calc(100% - 72px);
    }
  }

  .source-end {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 0px;

    img {
      top: 8px;
      left: auto;
      right: 8px;
      width: 40px;
      height: 40px;
    }

    .hint {
      // position: absolute;
      // right: 2px;
      // left: auto;
      background-color: var(--shade07);
      padding: 4px 12px;
      border-radius: 4px;
      position: relative;
      z-index: 2;
      top: 0;
      right: 40px;
      top: 16px;
    }
  }

  .password__hide__btn {
    position: absolute;
    left: auto;
    display: flex;
    align-items: center;
    top: 0;
    right: 20px;
    width: 20px;
    padding: 0;
    height: 100%;
    background-color: transparent;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: var(--landing-text-label);
        fill-opacity: 1;
      }
    }
  }

  img {
    position: absolute;
    left: 8px;
    top: 12px;
    width: 24px;
  }

  .custom-input__container.plug-text-1 {
    input {
      position: relative;
      left: 2px;
    }
  }

  .plug-text {
    position: relative;
    z-index: 1;
    left: 24px;
  }

  @include l670 {
    &.icon-JSX-start {
      .icon-JSX-start__div {
        left: 16px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .custom-input__input {
        margin-left: 32px;
        width: calc(100% - 32px);

        &.password {
          width: calc(100% - 72px);
        }
      }
    }

    &__gray-warning {
      font-size: 16px;
      line-height: 20px;
    }

    &__title {
      font-size: 16px;
    }

    &__container {
      margin-top: 8px;
      border-radius: 8px;
    }

    &.error-container,
    &.success-container {
      .text-error,
      .text-success {
        font-size: 16px;
      }
    }

    input {
      padding: 12px;
      font-size: 14px;

      &.password,
      &.icon {
        width: calc(100% - 20px);
      }
    }
    input[type="password"] {
      width: calc(100% - 20px);
    }

    &.icon-end-container {
      .custom-input__input {
        width: calc(100% - 52px);
      }
    }

    .source-end {
      width: 24px;
      height: 24px;
      top: 10px;

      img {
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
      }
    }

    .password__hide__btn {
      right: 16px;
      width: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    img {
      left: 8px;
      top: 6px;
      width: 20px;
    }

    .plug-text {
      left: 20px;
    }
  }
}
