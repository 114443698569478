.checkbox-info {
  display: flex;
  gap: 8px;
  align-items: start;

  label {
    color: var(--landing-text-label-2);
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }
}
