@import "../../../style/mixin";

.success-cancel-buttons {
  display: flex;
  gap: 8px;
  button {
    width: 100%;
  }

  @include lxs {
    flex-direction: column;
  }
}
