.chose-mode {
  &__content {
    display: flex;
    flex-direction: column;

    p {
      font-size: 14px;
      color: var(--landing-text-label-2);
      font-weight: 500;
    }
  }

  &__game {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &__title {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    img {
      border-radius: 16px;
      max-height: 180px;
      max-width: 100%;
      width: fit-content;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;

    button {
      width: 100%;
    }
  }
}
