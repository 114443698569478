@import "../../../../style/mixin";

.top-up-balance {
  &__bonus {
    display: flex;
    background: url("./present-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--green-80);
    border-radius: 12px;
    padding: 20px 12px;
    padding-bottom: 0;
    padding-right: 0;
    overflow: visible;

    &.fortune {
      width: 100%;

      & > div {
        width: 100%;
      }
    }

    img {
      margin-left: auto;
      margin-top: auto;
      height: fit-content;

      &.img-fortune {
        width: auto;
        margin: 4px auto;
        margin-top: 20px;
        max-height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 2px;

      &__item {
        display: flex;
        gap: 4px;

        &.column {
          flex-direction: column;
          .bonus-container {
            justify-content: end;
          }
        }

        & > span {
          display: flex;
          align-items: center;
        }
      }

      span {
        font-size: 18px;
        font-weight: 500;
      }

      .bonus,
      .currency {
        border-radius: 6px;
        padding: 4px;
      }

      .currency {
        background-color: var(--menu-count-text);
      }

      .bonus-container {
        display: flex;
        width: 100%;
        align-items: center;
      }

      .bonus {
        background-color: var(--pink-violet);
        margin-left: 4px;
      }
    }
  }

  @include l670 {
    &__bonus {
      padding: 12px 4px;

      &__info span {
        font-size: 12px;
      }
    }
  }
}
