.check-info {
  padding: 8px;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background-color: var(--success-check-bg);

  svg {
    width: 20px;
    height: auto;
  }

  p {
    font-size: 12px;
    color: var(--white);
  }
}
