@import "../../../../style/mixin";

.carousel-desk__header {
  display: flex;
  align-items: center;
  padding: 32px 0;

  &__right {
    margin-left: auto;
    display: flex;
    gap: 8px;

    .custom-button {
      font-size: 14px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 40px;
    }
  }

  .carousel-desk__controls {
    display: flex;
    align-items: center;
    gap: 4px;

    &__prev,
    &__next {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid var(--primary-select-border-bg);
      border-radius: 8px;
      background: var(--landing-item-bg-2);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--landing-item-bg);
      }
    }
  }

  .show-all {
    align-items: center;
  }

  @include l670 {
    .show-all {
      padding: 6px 12px;
      height: 32px;

      span {
        font-size: 10px;
      }
    }

    .carousel-desk__controls {
      &__prev,
      &__next {
        width: 32px;
        height: 32px;
      }
    }
  }

  @include lxs {
    padding: 16px 0;
  }
}
