.owl-carousel .owl-stage {
  display: flex;
}
.acarousel-item,
.carousel-item {
  height: 100%;
}

.carousel-desk {
  .games-rows {
    height: 100%;

    &-2 {
      height: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      gap: 12px;
    }
  }
}

.game-desk {
  &__item {
    background-color: transparent;

    &__photo {
      position: relative;
      background-color: var(--landing-item-bg);
      margin: 0 8px;
      display: flex;
      justify-content: center;
      border-radius: 16px;
      overflow: hidden;

      img {
        max-width: 100%;
        height: 225px;
      }
    }

    &__name {
      margin-top: 8px;
      font-size: 24px;
      font-weight: 600;
      line-height: 130%;
    }

    &__author {
      font-weight: 600;
      font-size: 20px;
      color: var(--landing-text-label);
    }

    &__favorite {
      top: 20px;
      right: 32px;
      position: absolute;
      background-color: transparent;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
