.loader-container-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 400px;

  &.full-height {
    min-height: 100svh;
  }
}

.loader-aside-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100svh;
  min-height: 400px;
}
