@import "../../../style/template.scss";
@import "../../../style/mixin";

.catalog-game {
  width: 100%;
  border-bottom: 3px solid var(--landing-border);
  margin-top: 12px;
  padding-bottom: 20px;
  @extend %borderLanding;

  //CRUTCH
  .providers {
    .game__item {
      padding: 0;
      background-color: transparent;
    }
    .game__item__online,
    .game__item__name {
      display: none;
    }
  }

  @include l670 {
    padding-bottom: 12px;
  }
}
