@import "../../../style/mixin";

.desk-title {
  display: flex;
  gap: 8px;
  font-size: 20px;

  span {
    font-weight: 700;
  }

  svg {
    width: auto;
    height: 20px;
    path {
      fill: var(--blue2);
    }
  }

  @include lsm {
    font-size: 18px;
  }

  @include l670 {
    font-size: 16px;
    gap: 6px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  @include lxs {
    font-size: 14px;
  }
}
