@import "../../../../style/mixin";

.jackpot-banner {
  &__content {
    position: fixed;
    width: 820px;
    height: 500px;
    background-color: var(--landing-background);
    background-image: url("./bg-jackpot-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    left: calc(50svw - 400px);
    top: calc(50svh - 250px);
    border-radius: 16px;
  }

  &__main {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    margin-top: 150px;

    .grande-jackpot {
      padding: 4px 8px;
      opacity: 1;
      span {
        font-size: 24px;
        text-transform: uppercase;
      }
    }

    h1 {
      font-size: 52px;
    }
  }

  @include lsm {
    &__content {
      width: 95svw;
      left: 2.5svw;
    }
  }

  @include l670 {
    &__content {
      top: calc(50svh - 30svw);
      height: 60svw;
      min-height: 300px;
    }

    &__main {
      gap: 20px;
      margin-top: 18svw;

      .grande-jackpot {
        span {
          font-size: 20px;
        }
      }

      h1 {
        font-size: 44px;
      }
    }
  }

  @include lxs {
    // NEED - мобильный баннер
    &__content {
      top: calc(50svh - 25svh);
      height: 50svh;
    }

    &__main {
      margin-top: 15svh;
      gap: 32px;
    }
  }

  @include llxs {
    &__main {
      gap: 20px;

      .grande-jackpot {
        span {
          font-size: 16px;
        }
      }

      h1 {
        font-size: 32px;
      }
    }
  }

  @media (max-height: 600px) {
    &__content {
      top: 5svh;
      max-height: 90svh;
    }
    &__main {
      margin-top: 20svh;
      gap: 32px;
    }
  }
}
