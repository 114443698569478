@import "../../../style/mixin";

.withdraw {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .custom-radio {
    margin-left: 24px;

    label {
      color: var(--menu-count-text);
    }
  }

  p {
    color: var(--landing-text-label-2);
    font-weight: 600;
  }

  &__check {
    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }

    &__amount {
      display: flex;
      align-items: center;
      gap: 4px;

      span {
        font-size: 13px;
      }
    }

    &__value {
      color: var(--gray2);
    }

    &__currency {
      color: var(--jackpot-currency);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    gap: 20px;

    &__card {
      background-color: var(--primary-search-bg);
      padding: 12px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__item {
        display: flex;
        justify-content: space-between;

        .label {
          color: var(--gray2);
          font-weight: 500;
        }

        .amount {
          display: flex;
          gap: 4px;
          span {
            font-weight: 600;
            font-size: 13px;

            &.currency {
              color: var(--gray2);
            }

            &.percent {
              font-weight: 400;
              background-color: var(--black);
              padding: 1px 4px;
              border-radius: 6px;
              font-size: 12px;
              min-width: 32px;
              align-items: center;
              white-space: nowrap;
              height: fit-content;
            }
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 12px;

    button {
      width: 100%;
    }
  }

  .custom-input__container {
    margin-top: 0;
  }

  .cus-select-container {
    height: 54px;

    .cus-select__options {
      top: 56px;
    }
  }

  .cus-select__container__title {
    text-align: start;
    .cus-select__value {
      &.placeholder {
        color: var(--menu-count-text);
      }
      font-size: 16px;
      font-weight: 500;
    }
  }

  // TODO - перетащить в селект
  @include l670 {
    .custom-radio label {
      font-size: 16px;
    }

    .cus-select-container {
      height: 44px;

      .cus-select__options {
        top: 46px;
      }
    }

    .cus-select__container__title {
      text-align: start;
      gap: 12px;

      .cus-select {
        border-radius: 8px;
        &__value {
          font-size: 14px;
        }
      }
    }
  }
}
