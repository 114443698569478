@import "../../../style/mixin";

.custom-button {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 6px;
  background-color: var(--primary-btn);
  border-radius: 12px;
  padding: 16px 24px 16px 24px;
  overflow: hidden;

  &.loading-btn {
    min-height: 48px;
  }

  &.two {
    background-color: var(--primary-btn-2);
    .custom-button__text {
      color: var(--primary-btn-2-text);
    }

    &:hover {
      background-color: var(--primary-btn-2-hover);
      .custom-button__text {
        color: var(--primary-btn-2-text-hover);
      }
    }

    &.disable {
      background-color: var(--primary-btn-2-disable);
      .custom-button__text {
        color: var(--primary-btn-2-text-disable);
      }
    }
  }

  &:hover {
    background-color: var(--primary-btn-hover);
  }

  &.blue {
    background-color: var(--blue);

    &:hover {
      background-color: var(--blue2);
    }
  }

  &.gradient-blue {
    // box-shadow:
    //   0px 4px 9.9px 0px rgba(0, 96, 255, 0.63),
    //   0px 7px 7.3px 0px rgba(0, 0, 0, 0.25);
    background: var(--gradient-blue);

    &:hover {
      background: rgba(0, 96, 255, 0.63);
    }
  }

  &.gradient-purpure {
    background: var(--gradient-purpure);
  }

  &.gray {
    background-color: var(--gray);

    &:hover {
      background-color: var(--gray2);
    }
  }

  &.green3 {
    background-color: var(--green-3);

    .custom-button__text {
      color: var(--segment-label);
    }
    &:hover {
      background-color: var(--green-2);
    }
  }

  &.border {
    border: 2px solid var(--shade3);
  }

  &__text {
    font-size: 14px;
    font-weight: 600;
  }

  .spinner-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
  }

  @include l670 {
    border-radius: 8px;
  }

  @include llxs {
    border-radius: 6px;
  }
}
