@import "../../../style/mixin";

.back-row {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 20px 0;

  h2 {
    font-size: 32px;
  }

  .arrow-back {
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 8px;

    &__title {
      display: flex;
      align-items: center;
      gap: 8px;

      svg path {
        fill: var(--green-3);
      }
    }

    &__btn {
      background-color: var(--primary-btn-disable);
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &__text {
      font-size: 12px;
      font-weight: 600;
      color: var(--menu-count-text);
    }
  }

  @include l670 {
    h2 {
      font-size: 20px;
    }

    .arrow-back {
      gap: 20px;

      &__text {
        font-size: 20px;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  @include lxs {
    h2 {
      font-size: 18px;
    }

    .arrow-back {
      gap: 12px;

      &__text {
        font-size: 16px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
