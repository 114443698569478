.withdraw__into-p {
  color: var(--landing-text-label);
  font-size: 20px;
  font-weight: 600;

  span {
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
  }
}
