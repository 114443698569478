.choose-currency-container {
  display: flex;
  gap: 4px;

  .replenish {
    padding: 8px;
    border-radius: 0 10px 10px 0;
    align-items: center;
    position: relative;
    z-index: 2;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .controls-btn {
    display: flex;
    gap: 4px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      &.minus {
        background-color: var(--primary-btn);

        &:hover {
          background-color: var(--primary-btn-hover);
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .controls-promo {
    margin-top: 4px;
    button {
      border-radius: 6px;
      background-color: var(--primary-btn);
      justify-content: center;

      &:hover {
        background-color: var(--primary-btn-hover);
      }

      span {
        font-size: 12px;
      }
    }
  }
}
