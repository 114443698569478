@import "../../../style/template.scss";
@import "../../../style/mixin";

.footer {
  padding: 72px 0;
  padding-bottom: 40px;
  margin-top: auto;

  &__content {
    background-color: var(--landing-item-bg);
    padding: 24px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__one,
    &__two,
    &__three {
      display: flex;
      align-items: center;
    }

    &__one {
      gap: 40px;
      .logo {
        padding-top: 0;

        &__text {
          white-space: nowrap;
        }
      }
    }
    &__two {
      justify-content: space-between;
      background-color: var(--landing-background);
      padding: 12px;
      border-radius: 12px;

      &__info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
          color: var(--landing-text-label-2);
        }

        &__title {
          font-size: 16px;
          font-weight: 700;
        }

        &__desk {
          font-size: 14px;
          font-weight: 500;
        }
      }

      &__links {
        display: flex;
        gap: 8px;

        div {
          @extend %default-item;
          padding: 0;
          height: 48px;
          cursor: pointer;

          &:hover {
            background-color: var(--landing-background);
          }
        }
      }
    }
    &__three {
      justify-content: space-between;

      .social-icons {
        margin-top: auto;
      }

      &__tg {
        display: flex;
        gap: 60px;
        align-items: center;
      }

      .footer__play-tg,
      .footer__support-tg {
        display: flex;
        gap: 12px;
        align-items: center;

        &:hover {
          .footer__play-tg__name,
          .footer__support-tg__name {
            color: var(--white);

            svg path {
              fill: var(--green-3);
            }
          }
        }

        &__title {
          color: var(--landing-text-label);
          font-size: 16px;
          font-weight: 600;
        }

        &__name {
          font-size: 14px;
          display: flex;
          gap: 4px;
          align-items: center;
          color: var(--landing-text-label-2);
          font-weight: 600;
        }

        svg {
          width: auto;
          height: 14px;

          path {
            fill: var(--telegram);
          }
        }
      }
    }

    &__contents {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &__title {
        font-size: 16px;
        font-weight: 600;
        color: var(--landing-text-label);
      }
      &__items {
        display: flex;
        align-items: center;
        gap: 16px;

        a,
        button {
          font-size: 14px;
          font-weight: 600;
          background-color: transparent;
          color: var(--landing-text-label-2);

          &:hover {
            color: var(--white);
          }
        }
      }
    }

    &__controls {
      display: flex;
      margin-left: auto;
      gap: 8px;

      .button-up {
        position: static;
        display: flex;
        align-items: center;
      }
    }

    .shose-lang {
      margin-right: 32px;
    }
  }

  &__copyright {
    color: var(--menu-count-text);
  }

  @include l1440 {
    &__content {
      position: relative;

      .logo {
        height: auto;
        img {
          height: 28px;
        }

        span {
          font-size: 16px;
        }
      }

      &__one {
        gap: 20px;
      }

      &__controls {
        position: absolute;
        top: 12px;
        right: 24px;
      }

      &__contents__items {
        margin-top: 20px;
        gap: 12px;

        a {
          gap: 12px;
          font-size: 14px;
        }
      }

      &__three__tg {
        gap: 20px;
        margin: 0 12px;

        .footer__play-tg,
        .footer__support-tg {
          gap: 8px;

          &__title {
            font-size: 14px;
          }

          &__name {
            font-size: 12px;
          }
        }
      }
    }

    &__copyright {
      font-size: 12px;
    }
  }

  &.mobile {
    .footer__content {
      padding: 16px;

      &__two {
        flex-direction: column;
        gap: 20px;
        align-items: start;

        &__links {
          flex-wrap: wrap;
        }
      }

      &__three {
        flex-direction: column;
        align-items: start;
        gap: 20px;

        &__tg {
          margin: 0;
          .footer__play-tg__title,
          .footer__support-tg__title {
            font-size: 14px;
          }
        }

        .footer__copyright {
          font-size: 14px;
        }
      }
    }
    @include ltb {
      .footer__content__contents__items {
        flex-wrap: wrap;
      }
    }

    @include l670 {
      padding-top: 40px;

      .footer__content__three__tg {
        align-items: baseline;
        flex-direction: column;
      }
    }

    @include lxs {
      padding-bottom: 110px;

      .logo {
        padding: 0;

        img {
          height: 24px;
          width: auto;
        }

        &__text {
          font-size: 14px;
        }
      }

      .chose-lang {
        height: 40px;
        .lang-item {
          gap: 4px;

          svg {
            height: 20px;
          }
        }
      }

      .button-up {
        padding: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: none;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.russian .footer {
  @include l1440 {
    &__content__three__tg {
      .footer__play-tg,
      .footer__support-tg {
        gap: 8px;

        &__title {
          font-size: 12px;
        }

        &__name {
          font-size: 12px;
        }

        svg {
          width: auto;
          height: 10px;
        }
      }
    }

    &__copyright {
      font-size: 12px;
    }
  }
}
