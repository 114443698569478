@import "../../../../style/template";
@import "../../../../style/mixin";

.menu__providers {
  h2 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    svg {
      width: auto;
      height: 14px;
    }
  }

  &__list {
    margin-top: 6px;
    display: flex;
    flex-direction: column;

    &__item {
      background-color: transparent;
      outline: none;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 4px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: var(--menu-count-bg-hover);

        .menu__providers__list__item {
          &__title {
            color: var(--landing-text-label-hover);
          }

          &__count {
            background-color: var(--blue);
            span {
              color: var(--menu-count-text-hover);
            }
          }
        }
      }

      &.active {
        background-color: var(--menu-count-bg-active);
        .menu__providers__list__item {
          &__count {
            background-color: var(--blue);
            span {
              color: var(--white);
            }
          }
        }
      }

      img {
        width: 24px;
        height: 24px;
      }

      &__title {
        @extend %one-string;
        font-size: 14px;
        font-weight: 600;
        color: var(--landing-text-label);
      }

      &__count {
        margin-left: auto;
        min-width: 28px;
        padding: 0 4px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-weight: 600;
        font-size: 12px;
        background-color: var(--landing-item-bg-2);

        span {
          color: var(--menu-count-text);
          font-weight: 600;
        }
      }
    }
  }

  @include l1220 {
    &__list__item {
      &__title {
        font-size: 12px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
