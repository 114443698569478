.show-balance {
  &__overlay {
    position: fixed;
    inset: 0px;
    z-index: 100;
  }

  &.mini {
    padding: 16px;
    border: 1px solid var(--shade3);
    bottom: 150px;
    top: auto;
    left: 5svw;
    width: 90svw;
  }

  h2 {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: var(--green-3);
      }
    }
  }

  &__fields {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--shade2);
      border-radius: 12px;
      padding: 8px 12px;

      .green {
        color: var(--green-3);
      }

      .text {
        font-weight: 600;
      }

      .value {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .currency {
        padding: 6px;
        border-radius: 8px;
        background-color: var(--shade4);
      }
    }
  }
}
