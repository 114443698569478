@import "../components/games/games-games.scss";
@import "../style/template.scss";
@import "../style/mixin";

.landing,
.provider-games,
.games,
.banner-page {
  background-color: var(--landing-background);
  min-height: 100svh;
}

.games {
  &__content__content .back-row {
    margin-top: 10px;
  }

  // .find-game {
  //   max-width: 1000px;
  // }

  &__providers {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 100px;

    h2 {
      font-size: 42px;
    }
  }

  @include l1220 {
    &__providers {
      gap: 64px;
    }

    &__content__content {
      margin-top: 32px;
    }
  }

  @include l670 {
    &__providers {
      gap: 40px;

      h2 {
        font-size: 20px;
      }
    }

    &__item__name {
      padding: 8px 0;
    }
  }
}
