@import "../../../style/mixin";
@import "../../../style/template";

.chose-lang.choose-currency {
  &:hover {
    ul {
      left: -12px;

      li {
        padding: 8px 20px;
      }
    }
  }
}

.chose-lang {
  @extend %default-item;
  position: relative;
  color: var(--white);
  padding: 8px;
  border-radius: 12px;
  background-color: var(--landing-item-bg-2);
  border: 1px solid var(--landing-item-bg-border);

  &:hover {
    background-color: var(--landing-item-bg);
  }

  .text {
    font-size: 18px;
    font-weight: 600;
    color: var(--landing-text-label);

    &.currency-text {
      font-size: 20px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  ul {
    display: none;
    min-width: 80px;
  }

  &__plug {
    display: none;
    width: 100%;
  }

  .lang-item {
    display: flex;
    align-items: center;
    min-width: 86px;
    font-size: 18px;
    gap: 8px;

    button {
      display: flex;
      align-items: center;
      min-width: 86px;
      font-size: 18px;
      gap: 8px;
      background-color: transparent;
      color: var(--white);
    }

    &.select {
      font-weight: 600;
    }

    &.main {
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      min-width: fit-content;

      svg {
        width: 28px;
        height: 100%;
      }

      .text svg {
        width: 16px;
        height: auto;
      }

      &:hover,
      &:focus {
        color: var(--gray-black-hover);

        .arrow {
          opacity: 0.6;
        }
      }
    }

    span {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    img {
      width: 20x;
      height: 14px;

      &.arrow {
        width: 24px;
        height: 24px;
      }
    }
  }

  &:hover,
  &:focus {
    .chose-lang__plug {
      display: flex;
      position: absolute;
      bottom: 16px;
      left: 0;
      background-color: transparent;
      // background-color: #0f0;
      height: 44px;
      z-index: 15;
      width: 120px;
    }
    .close + .chose-lang__plug {
      display: none;
    }

    ul {
      bottom: 44px;
      z-index: 16;
      left: -14px;

      &.ul-for-only-flag {
        right: -12px;
      }

      &.ul-for-mobile {
        right: -10px;
      }

      border-radius: 16px;
      background: var(--landing-background);
      box-shadow:
        0px 29px 55px 0px rgba(0, 0, 0, 0.67),
        0px 4px 13px 0px rgba(43, 159, 196, 0.03) inset,
        12px 43px 12px 0px rgba(0, 0, 0, 0.25),
        -28px 19px 16px 0px rgba(0, 0, 0, 0.25);
      position: absolute;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      list-style: none;
      overflow: hidden;

      &.close {
        display: none;
      }

      li {
        padding: 4px 20px;

        svg {
          width: 28px;
          height: 100%;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--landing-item-bg);
        }
      }
    }
  }
  @include l1220 {
    .text {
      &.currency-text {
        font-size: 18px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  @include lsm {
    .text {
      &.currency-text {
        font-size: 16px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
