@import "../../../style/mixin";

.cus-select {
  position: relative;
  padding: 8px 16px;
  cursor: pointer;
  background-color: var(--primary-select-bg);
  border-radius: 16px;
  height: 100%;
  align-items: center;
  display: flex;

  &:hover {
    background-color: var(--primary-select-bg-hover);
  }

  &__container__title {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__container__title,
  &__input {
    border-radius: 16px;
  }

  &__container__title,
  &__input,
  &__input-text {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
  }

  &__input {
    display: flex;
    align-items: center;
    border: 3px solid var(--landing-border-input);
    padding: 0px;
    gap: 8px;

    svg {
      width: 20px;
      height: 24px;
    }

    &-text {
      &::placeholder {
        color: var(--landing-placeholder);
      }
      font-size: 24px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 0%;
    }

    input {
      font-size: 14px;

      &::placeholder {
        color: var(--landing-placeholder);
        font-weight: 600;
      }
    }
  }

  &__options {
    position: absolute;
    left: 0px;
    top: 44px;
    width: 100%;
    background-color: var(--landing-item-bg);
    border-radius: 0px 0px 8px 8px;
    padding: 0px;
    overflow: auto;
    z-index: 99;

    ul {
      overflow: auto;
      max-height: 320px;
    }

    &__li {
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 8px;

      &:hover {
        background-color: var(--primary-search-bg-chosen-hover);
      }

      &:first-child {
        padding-top: 8px;
      }
      &:last-child {
        padding-bottom: 8px;
      }

      &__label {
        font-size: 14px;
        line-height: 120%;
        color: var(--landing-text-label);
      }
    }
  }

  .arrow {
    position: absolute;
    z-index: 1;
    right: 12px;
    top: calc(50% - 12px);
    width: 16px;
    height: 16px;
  }

  @include lxs {
    &__container__title,
    &__input {
      border-radius: 12px;
    }

    &__input {
      &-text {
        font-size: 16px;
      }
    }

    &__options {
      top: 54px;
      &__li {
        cursor: pointer;
        padding: 8px 16px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}
