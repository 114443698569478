@import "../../style/mixin";

.default-modal {
  position: fixed;
  width: 600px;
  top: 5svh;
  max-height: 90svh;
  overflow: auto;
  background-color: var(--modal-bg);
  left: calc(50svw - 300px);
  padding: 44px;
  border-radius: 20px;

  .modal__close {
    right: 44px;
  }

  .close-modal {
    z-index: 2;
  }

  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 26px;
  }

  &.mini {
    width: 400px;
    left: calc(50svw - 200px);
    padding: 20px;
    .modal__close {
      right: 20px;
    }

    h2 {
      text-align: left;
      font-size: 20px;
    }
  }

  &.big {
    top: 2.5svh;
    max-height: 95svh;
    width: 1000px;
    left: calc(50svw - 500px);
    padding: 40px;

    .modal__close {
      right: 40px;
    }

    h2 {
      font-size: 32px;
    }
  }

  &.full {
    top: 0;
    left: 0;
    width: 100svw;
    height: 100svh;
    max-height: 100svh;
    border-radius: 0;
  }

  @include l1220 {
    padding: 32px;

    .modal__close {
      right: 32px;
    }

    h2 {
      font-size: 22px;
    }
  }

  @include smallDesktop {
    width: 90svw;
    left: calc(5svw);
    padding: 20px;

    .modal__close {
      right: 20px;
    }
  }

  @include l670 {
    width: 90svw;
    top: 5svh;
    max-height: 90svh;
    left: 5svw;
    padding: 12px;
    border-radius: 12px;

    .modal__close {
      right: 12px;
    }

    h2 {
      font-size: 16px;
    }

    &.mini {
      width: 400px;
      left: calc(50svw - 200px);
    }
  }

  @include lxs {
    padding: 16px;

    .modal__close {
      right: 16px;
    }

    &.mini {
      width: 95svw;
      left: 2.5svw;
      top: 2.5svh;
      max-height: 95svh;
      padding: 8px;

      .modal__close {
        right: 8px;
      }

      h2 {
        font-size: 14px;
      }
    }
  }

  @include llxs {
    h2 {
      font-size: 14px;
    }

    .custom-button span {
      font-size: 14px;
    }
  }

  @include mobile {
    &.full {
      padding: 8px;
    }
  }
}
