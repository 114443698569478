@import "../../../style/template.scss";
@import "../../../style/mixin";

.banners {
  display: grid;
  grid-template-columns: 1fr;
  margin: 12px 0;
  margin-top: 20px;
  gap: 12px;

  &.two {
    grid-template-columns: 1fr 1fr;
  }

  &.owl-carousel .owl-item img {
    width: auto;
  }

  .dynamic-banners {
    overflow: auto;
    padding: 0;
    display: flex;
    align-items: center;

    & > div {
      width: 100%;
    }
  }

  .banners__bonus,
  .banners__welcome {
    position: relative;
    overflow: hidden;
    padding: 36px 32px;
    border-radius: 20px;

    h2 {
      font-size: 38px;
      font-weight: 900;
      line-height: 115%;
      color: var(--white);
    }

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 110%;
      color: var(--white);
      margin-top: 12px;
    }

    img {
      position: absolute;
      right: -12px;
      bottom: 0;
      height: 90%;
    }

    button,
    a {
      margin-top: 60px;
      width: fit-content;
    }
    .bottom-blur {
      position: absolute;
      width: 100%;
      height: 72px;
      left: 0;
      bottom: -36px;
      filter: blur(24px);
    }
  }

  &__welcome,
  &__bonus {
    &__info {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 60%;
      z-index: 1;
      height: 100%;
    }
  }

  &__welcome {
    background: var(--blue) url("./welcom-bg.png");
    background-size: cover;

    .bottom-blur {
      background: var(--blue2);
    }
  }

  &__bonus {
    overflow: hidden;
    background: var(--green) url("./bonus-bg.png");
    background-size: cover;

    &__custom {
      img {
        height: 100%;
        max-width: 100%;
        border-radius: 16px;
      }
    }

    .bottom-blur {
      background: var(--green);
    }
  }

  @include l1440 {
    .banners__bonus,
    .banners__welcome {
      padding: 20px 16px;

      h2 {
        font-size: 26px;
      }

      p {
        font-size: 14px;
      }

      img {
        right: -20px;
        bottom: 0;
        height: 90%;
      }

      button {
        margin-top: 40px;
      }
    }
  }

  @include lsm {
    .banners__bonus,
    .banners__welcome {
      h2 {
        font-size: 24px;
      }
    }

    &__welcome__info,
    &__bonus__info {
      width: 65%;
    }
  }

  @include ltb {
    grid-template-columns: 1fr;

    &.two {
      grid-template-columns: 1fr 1fr;
    }

    .banner-item__img,
    &__bonus__custom img {
      border-radius: 8px;
    }

    .banners__bonus,
    .banners__welcome {
      h2 {
        font-size: 18px;
      }
    }

    &__welcome__info,
    &__bonus__info {
      width: 70%;
    }
  }

  @include l670 {
    &.two {
      grid-template-columns: 1fr;
    }
  }

  @include lxs {
    grid-template-columns: 1fr;
  }

  @include llxs {
    .banners__bonus,
    .banners__welcome {
      img {
        right: -60px;
      }
    }
  }
}
