@import "../../../style/mixin";

.find-game-line {
  display: grid;
  // grid-template-columns: 1fr 260px;
  grid-template-columns: 1fr;
  gap: 8px;
  margin: 0 18px;

  select {
    min-height: 48px;
  }

  @include lxs {
    margin: 0;
    grid-template-columns: 1fr;

    .cus-select-container {
      height: 52px;

      .cus-select {
        border-radius: 12px;
      }
    }
  }
}
