@import "../../../style/template";
@import "../../../style/mixin";

#root .last-champion * {
  transition: 0s;
}

.last-champion {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 8px;

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;

    svg {
      height: 18px;
      width: auto;
    }

    &__online {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: 16px;
    }
  }

  &__carousel {
    display: flex;

    .champion__item {
      @extend %default-item;
      border: 1px solid var(--landing-item-bg-border);
      display: flex;
      gap: 8px;

      // - используется img а не __img, что бы перебить стиль из библиотеки owl
      img {
        height: 54px;
        border-radius: 8px;
        width: auto;
      }

      &__info {
        display: flex;
        flex-direction: column;
        overflow: auto;
        gap: 4px;

        &__title {
          @extend %one-string;
          font-weight: 600;
          font-size: 14px;
          color: var(--landing-text-label-2);
        }

        &__balance {
          @extend %one-string;
          display: flex;
          gap: 4px;
          align-items: center;

          &__count {
            color: var(--white);
            font-size: 16px;
            font-weight: 700;

            .opacity {
              font-size: 16px;
              font-weight: 700;
              opacity: 0.3;
            }
          }
        }
      }
    }
  }

  @include l1440 {
    gap: 16px;

    &__carousel {
      .champion__item {
        &__info {
          &__title {
            font-size: 12px;
          }

          &__balance__count {
            font-size: 12px;
            .opacity {
              font-size: 12px;
            }
          }
        }

        img {
          height: 44px;
        }
      }
    }
  }

  @include lxs {
    margin-top: 8px;

    &__title {
      font-size: 14px;

      &__online {
        margin: 0;
      }
    }
  }
}
