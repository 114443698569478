@import "../style/template.scss";
@import "../style/mixin";

.terms {
  &__content {
    @extend %home-content;
    padding: 20px;
    h1 {
      font-size: 28px;
      margin: 16px 0;
    }

    h2 {
      font-size: 24px;
      margin: 12px 0;
    }

    h3 {
      font-size: 20px;
      margin: 10px 0;
    }
  }

  @include lsm {
    &__content {
      padding: 16px;
      h1 {
        font-size: 24px;
        margin: 12px 0;
      }

      h2 {
        font-size: 20px;
        margin: 10px 0;
      }

      h3 {
        font-size: 16px;
        margin: 8px 0;
      }
    }
  }

  @include l670 {
    &__content {
      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 16px;
      }

      h3 {
        font-size: 14px;
      }
    }
  }
  @include llxs {
    &__content {
      padding: 12px;
    }
  }
}
