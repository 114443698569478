@import "../../../style/mixin";
@import "../../../style/template";

.default-modal.login-modal {
  background-color: transparent;
  width: 800px;
  left: calc(50vw - 400px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding: 0;

  .close-modal {
    z-index: 2;
  }

  h2 {
    font-size: 24px;
    text-align: start;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: var(--landing-text-label-2);
  }

  .login {
    &-fields,
    &-img {
      @extend %default-item;
      border-radius: 20px;
    }

    &-img {
      padding: 0;
      bottom: 0;
      position: relative;
      overflow: hidden;

      background-image: url("./register-banner.png");
      background-repeat: no-repeat;
      background-position-x: center;
      background-size: cover;
      display: flex;
      flex-direction: column;

      &__text {
        margin-top: 44px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      &__welcome {
        border-radius: 8px;
        padding: 8px 12px;
        background-color: var(--green-2);
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        color: var(--white);
        text-transform: uppercase;
        cursor: pointer;
      }

      &__name,
      &__to {
        font-size: 32px;
        font-weight: 700;
        color: var(--white);
        text-transform: uppercase;
      }

      &__name {
        margin-top: 8px;
      }

      &__to {
        position: absolute;
        text-align: center;
        top: calc(50% - 14px);
        width: 100%;
        left: 0%;
        font-size: 18px;
      }

      img {
        margin-top: auto;
      }
    }

    &-fields {
      padding: 24px;
      background-color: var(--login-bg);

      .forgot-pas {
        margin: 0 auto;
        margin-top: 20px;
        color: var(--green-3);
        font-size: 14px;
        font-weight: 600;
        display: block;
        text-align: center;
        cursor: pointer;
        background-color: transparent;

        &:hover {
          color: var(--green-2);
        }
      }
    }

    &__segment {
      margin-top: 20px;
    }

    &__form {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      gap: 12px;

      .custom-input__container {
        margin-top: 0;
      }
    }

    &__auth {
      margin-top: 12px;
      display: flex;
      justify-content: center;
    }
  }

  .checkbox-info {
    span {
      color: var(--landing-text-label-2);
      font-weight: 600;
    }

    a {
      font-weight: 600;
      color: var(--green-3);
    }
  }

  .tg__title {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 20px;

    &__left,
    &__right {
      height: 0.1px;
      width: 100%;
      background: var(--landing-text-label-2);
    }

    &__text {
      font-size: 12px;
      font-weight: 600;
      color: var(--menu-count-text);
    }
  }

  .dynamic-img-container {
    display: block;
    // background-image: none;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // position: relative;

    // .blur {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   filter: blur(8px);
    //   opacity: 0.7;
    // }

    img {
      margin: 0;
      // height: 100%;
      // object-fit: cover;
      z-index: 1;
    }
  }

  @include lsm {
    width: 95svw;
    left: 2.5svw;
    grid-template-columns: 372px 1fr;

    & > div {
      width: 100%;
    }
  }

  @include ltb {
    grid-template-columns: 276px 1fr;
  }

  @include l670 {
    grid-template-columns: 1fr;

    .login {
      &-img {
        height: 300px;
        overflow: hidden;
        background-image: url("./register-banner-mobile.png");

        &.dynamic-img-container {
          background-image: none;
          img {
            left: 0;
          }
        }

        img {
          object-fit: contain;
          height: inherit;
          position: relative;
          margin-top: 0;
          left: 25svw;
        }

        &__text {
          position: absolute;
          top: 40px;
          left: 20px;
          margin-top: 0;
        }

        &__welcome {
          font-size: 16px;
        }

        &__name {
          font-size: 18px;
        }
      }

      &-fields {
        padding: 12px;
      }

      &__tg__title {
        margin-top: 20px;
        font-size: 14px;
      }

      &__segment {
        margin-top: 20px;
      }

      &__form {
        margin-top: 20px;
        gap: 16px;
      }
    }
  }

  @include lxs {
    .login-img {
      height: 180px;
      background-position-y: 100%;

      &.dynamic-img-container {
        background-position-y: 0;

        img {
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: auto;
        }
      }

      &__text {
        top: 20px;
        left: 12px;
      }

      &__welcome {
        font-size: 12px;
      }

      img {
        bottom: -5%;
        height: 120%;
        left: 15svw;
      }
    }
  }

  @include llxs {
    .login-img img {
      bottom: -15%;
      height: 100%;
      right: 10svw;
    }
  }
}
