@import "../../../style/mixin";

.fortune-modal {
  background: var(--modal-bg) url(./img/fortune-bg.webp) no-repeat 50% / cover;
  overflow: hidden;

  section.container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  header {
    margin-top: 12px;
    gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    h2,
    h3 {
      text-align: center;
      text-shadow: 1px 1px 5px var(--black);
      text-transform: capitalize;
    }

    h2 {
      font-size: 40px;
      text-align: center;
    }

    h3 {
      font-size: 28px;
      text-align: center;
    }
  }

  .fortune {
    &__logo {
      width: fit-content;
    }

    &__currency {
      border-radius: 12px;
      margin-left: auto;

      .choose-currency__wrapper {
        border-radius: 12px;
      }
    }
  }

  main {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .chose-lang {
    display: flex;
    margin-left: auto;
    z-index: 5;
  }

  .zeus,
  .go-zeus {
    z-index: 3;
    position: absolute;
    height: 100%;
  }

  .zeus {
    bottom: -15%;
    transform: translateX(calc(-100% + 50px));
    animation: moveZeus 2s ease-in-out infinite normal;
  }

  .go-zeus {
    bottom: -35%;
    // transform: translateX(calc(-100% + 50px));
    transform: translateX(calc(-50% - 230px));
  }

  @include l1220 {
    background: var(--modal-bg) url(./img/fortune-bg-small.webp) no-repeat 50% / cover;

    header {
      margin-top: 20px;
      h2 {
        font-size: 32px;
      }

      h3 {
        font-size: 24px;
      }
    }

    .zeus {
      z-index: -1;
    }
  }

  @include smallDesktop {
    header {
      margin-top: 32px;
    }

    .zeus {
      height: 60%;
      bottom: 10%;
    }
  }

  @include lsm {
    header {
      position: relative;
      z-index: 10;
      grid-template-columns: 1fr 1fr;
      .fortune__title {
        position: absolute;
        top: 80px;
        width: 100%;
      }
    }
  }

  @include l670 {
    .zeus {
      left: 50%;
      top: 20%;
      animation: moveZeusMobile 5s ease-in-out infinite normal;
    }
  }

  @include mobile {
    .modal__close {
      top: 60px;
      z-index: 5;
    }

    &.no-auth {
      .modal__close {
        top: 8px;
      }
    }

    header {
      margin-top: 0px;
    }

    main {
      margin-top: 12px;
    }

    h3 {
      line-height: 110%;
    }
    h2 {
      line-height: 100%;
    }
  }

  @include lxs {
    .chose-lang {
      margin-left: 0;
      margin-right: auto;
    }
  }

  @include smLsp {
    header {
      grid-template-columns: 1fr 1fr 1fr;

      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 16px;
      }

      .fortune__title {
        position: static;
      }
    }

    .zeus {
      top: 30%;
    }

    main {
      margin-top: 8px;
    }
  }

  @keyframes moveZeus {
    0% {
      transform: translateX(calc(-100% + 100px));
    }
    50% {
      transform: translateX(calc(-100% - 0px));
    }
    100% {
      transform: translateX(calc(-100% + 100px));
    }
  }

  @keyframes moveZeusMobile {
    0% {
      transform: translateX(calc(-100% + 0px));
    }
    50% {
      transform: translateX(calc(-100% - 50px));
    }
    100% {
      transform: translateX(calc(-100% + 0px));
    }
  }
}
