@import "../../../style/template.scss";
@import "../../../style/mixin";
$cardGap: 8px;

%cardItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  gap: $cardGap;
}

.jackpot {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;

  &__main {
    @extend %cardItem;
    align-items: start;

    .icon-start {
      position: absolute;
      width: 24px;
      height: 24px;
    }

    &__title {
      position: relative;
      color: var(--menu-count-text);
      font-size: 14px;
      font-weight: 700;

      .icon-start {
        right: -32px;
        top: -4px;
      }
    }

    &__balance {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;

      &__value {
        font-size: 28px;
      }

      span {
        font-weight: 700;
      }

      &__currency {
        color: var(--jackpot-currency);
      }

      .icon-start {
        top: -8px;
        right: -24px;
      }
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $cardGap;

    .jackpot__card {
      @extend %cardItem;
      background-repeat: no-repeat;
      background-size: cover;

      &:nth-child(1) {
        background-image: url("./card-bg-green.png");
        .jackpot__card__balance__current {
          color: var(--card-currency-green);
        }
      }
      &:nth-child(2) {
        background-image: url("./card-bg-blue.png");
        .jackpot__card__balance__current {
          color: var(--card-currency-blue);
        }
      }
      &:nth-child(3) {
        background-image: url("./card-bg-viotel.png");
        .jackpot__card__balance__current {
          color: var(--card-currency-violet);
        }
      }

      &__title {
        display: flex;
        flex-direction: row-reverse;
        gap: 4px;
        color: var(--jackpot-card-title);
        font-size: 18px;
        font-weight: 700;

        svg {
          width: auto;
          height: 18px;
        }
      }

      &__balance {
        display: flex;
        gap: 4px;
        span {
          font-size: 24px;
          font-weight: 700;
          line-height: 120%;
        }
      }
    }
  }

  @include b1580 {
    .jackpot__main__balance__value {
      font-size: 32px;
    }
  }

  @include l1440 {
    gap: 12px;

    &__main {
      .icon-start {
        width: 24px;
        height: 24px;
      }

      &__title {
        font-size: 14px;

        .icon-start {
          right: -32px;
          top: -4px;
        }
      }

      &__balance {
        gap: 8px;

        span {
          font-size: 22px;
        }

        .icon-start {
          top: -12px;
          right: -24px;
        }
      }
    }

    &__main,
    &__cards .jackpot__card {
      padding: 12px;
    }

    &__cards {
      .jackpot__card {
        &__title {
          font-size: 16px;

          svg {
            height: 18px;
          }
        }

        &__balance {
          span {
            font-size: 20px;
          }
        }
      }
    }
  }

  @include lsm {
    &__main {
      &__balance {
        span {
          font-size: 18px;
        }
      }
    }

    &__cards {
      .jackpot__card {
        &__title {
          font-size: 14px;
        }

        &__balance {
          align-items: baseline;

          span {
            font-size: 16px;
            &.jackpot__card__balance__current {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @include ltb {
    display: flex;
    flex-direction: column;

    &__card {
      &:first-child {
        .jackpot__card__title {
          flex-direction: row;
        }
      }
    }

    &__main {
      align-self: center;
    }
  }

  @include lxs {
    &__card {
      &:first-child {
        .jackpot__card__title {
          flex-direction: row-reverse;
        }
      }
    }

    &__cards {
      grid-template-columns: 1fr;
    }
  }

  @include llxs {
    .cicle-info__popup {
      padding: 10px 8px;
      font-size: 12px;
      width: 200px;
      left: calc(-100px + 50%);
    }
  }
}
