@import "../../../style/mixin";

.slick-list .game__item,
.slick-list .provider__item {
  &:hover {
    box-shadow: none;
    scale: 1.02;
  }
}

.game__item,
.provider__item {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: var(--game-item);
  border-radius: 12px;
  padding: 6px;

  img {
    border-radius: 12px;
  }

  &__photo {
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 8px;

    img {
      max-width: 100%;
      height: 140px;
      width: 100%;
      object-fit: cover;
    }
  }

  &__provider {
    padding: 0 4px;
    display: flex;
    align-items: baseline;
    gap: 4px;

    svg {
      width: auto;
      height: 12px;
    }

    span {
      color: var(--landing-text-label-2);
      font-size: 12px;
      font-weight: 700;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: 600;
    padding: 0 8px;
  }

  &__author {
    font-weight: 600;
    font-size: 20px;
    color: var(--landing-text-label);
  }

  &__favorite,
  &__online {
    position: absolute;
    top: 8px;
    min-width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: var(--game-item-favorites);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__favorite {
    right: 8px;

    &.favorite {
      svg path {
        fill: var(--red);
      }
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__online {
    left: 8px;
    padding: 0 8px;
    display: flex;
    gap: 4px;

    span {
      font-size: 14px;
      font-weight: 600;
      color: var(--white);
    }
  }

  @include b1800 {
    &__photo img {
      height: 160px;
    }
  }

  // @include b2k {
  //   &__photo img {
  //     height: 13rem;
  //   }
  // }

  @include l1440 {
    &__photo img {
      height: 130px;
    }
  }

  @include l1220 {
    &__photo img {
      height: 120px;
    }
  }

  @include l670 {
    &__favorite,
    &__online,
    &__favorite,
    &__online {
      border-radius: 6px;
    }

    &__photo img {
      height: 110px;
    }

    &__favorite {
      right: 10px;
      top: 10px;
    }

    &__name {
      font-size: 16px;
    }
  }

  @include lxs {
    &__online {
      left: 4px;
      padding: 0 6px;
    }

    &__favorite {
      right: 4px;
    }

    &__online,
    &__favorite {
      svg {
        width: 14px;
        height: 14px;
      }

      span {
        font-size: 14px;
      }
    }

    &__name {
      font-size: 14px;
    }
  }

  @include llxs {
    padding: 0;

    &__photo img {
      height: 80px;
    }

    &__name {
      font-size: 10px;
      padding: 4px 8px;
    }

    &__online {
      svg {
        display: none;
      }
    }
    &__online,
    &__favorite {
      padding: 0;
      min-width: 24px;
      min-height: 16px;
      height: 24px;

      svg {
        width: 12px;
        height: 12px;
      }

      span {
        font-size: 12px;
      }
    }
  }

  // @include sm {
  //   &__favorite {
  //     right: 1rem;
  //     top: 1rem;
  //   }

  //   &__name {
  //     font-size: 1.6rem;
  //   }
  // }
}

.provider__item__photo {
  margin-bottom: 0;
}

.provider__item {
  &__photo img {
    width: 100%;
    height: auto;
  }
}

.provider__item__online,
.provider__item__favorite {
  display: none;
}
