@import "../../../style/mixin";

.show-new-pas__container.default-modal.mini {
  padding: 0;
}

.show-new-pas {
  left: calc(50svw - 200px);
  width: 400px;
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: fixed;

  .modal__close {
    z-index: 11;
  }

  h2 {
    text-align: left;
  }

  &__img {
    background-color: var(--blue2);
    background-image: url("./banner-bg-lock.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    height: 180px;
    border-radius: 20px;

    img {
      height: 100%;
    }
  }

  &__fields {
    position: relative;
    background-color: var(--login-bg);
    padding: 20px;
    border-radius: 20px;

    p {
      color: var(--landing-text-label-2);
      font-weight: 600;
    }

    &__forms {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  @include lxs {
    width: 95svw;
    left: 2.5svw;
  }
}
