@keyframes show {
  0% {
    height: 0;
    padding: 0;
    opacity: 0.5;
  }

  80% {
    height: 96px;
    padding: 16px 8px;
  }

  100% {
    padding: 16px 8px;
    height: auto;
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    padding: 16px 8px;
    height: auto;
    opacity: 1;
  }

  20% {
    height: 96px;
    padding: 16px 8px;
  }

  100% {
    height: 0;
    padding: 0;
    opacity: 0.5;
  }
}

.bottom-navigation {
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 101;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--shade1);
  padding: 0;
  height: 0;
  transition: 2s;
  border-top: 1px solid var(--shade3-08);

  &.show {
    animation-name: show;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &.hide {
    animation-name: hide;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    .circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: transparent;
    }

    .icon {
      border-radius: 6px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: var(--shade5);
      }
    }

    &.balance {
      .icon,
      .circle {
        background: var(--gradient-blue);
        border: 1px solid var(--shade3);
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: var(--white);
        }
      }

      &:hover {
        @media (hover: hover) {
          .icon {
            background: transparent;
            border: none;
          }

          .text {
            color: var(--white);
          }

          svg path {
            fill: var(--green-3);
          }

          .circle {
            background: var(--green-3);
          }
        }
      }

      &.active {
        .icon {
          background: transparent;
          border: none;
        }

        .text {
          color: var(--white);
        }

        svg path {
          fill: var(--green-3);
        }

        .circle {
          background: var(--green-3);
        }
      }
    }

    &:hover {
      svg path {
        fill: var(--white-80);
      }

      .text {
        color: var(--white-80);
      }
    }

    &.active {
      svg path {
        fill: var(--white);
      }

      .text {
        color: var(--white);
      }
    }

    .text {
      font-size: 13px;
      color: var(--shade5);
    }
  }
}
