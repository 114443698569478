@import "../../../../style/mixin";

.fortune-bonus {
  position: absolute;
  animation:
    showBonus 2s ease-in-out 1 normal forwards,
    dragBonus 2s ease-in-out infinite normal 2s;
  width: 400px;
  height: 400px;
  height: auto;
  right: 50px;
  height: auto;
  top: 0;
  z-index: 11;

  &__podium {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47%;
    left: 28%;
    top: 43.5%;
    height: 15%;
  }

  &__title {
    font-size: 24px;
    font-weight: 900;
    text-shadow: 1px 1px 3px var(--black);
    text-align: center;
    line-height: 110%;
    // color: var(--yellow);
  }

  img {
    width: 100%;
  }

  @include smallDesktop {
    right: auto;
    &__title {
      font-size: 20px;
    }
  }

  @include l670 {
    &__title {
      font-size: 14px;
    }
  }

  @include sm {
    width: 80svw;
    right: 10svw;
  }

  @include smLsp {
    width: auto;
    height: 40svh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;

    img {
      width: auto;
      height: 100%;
    }
  }

  @keyframes showBonus {
    0% {
      transform: translateY(200%);
    }
    50% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(70%);
    }
  }

  @keyframes dragBonus {
    0% {
      transform: translateY(70%);
    }
    50% {
      transform: translateY(60%);
    }
    100% {
      transform: translateY(70%);
    }
  }
}
