.copy-value {
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    background-color: var(--black);
    width: 24px;
    height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: var(--green-3);
      }
    }
  }
}
