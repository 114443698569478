@import "../../../style/mixin";

.modal__close {
  background-color: var(--landing-item-bg);
  border: 1px solid var(--landing-item-bg-border);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  right: 20px;
  top: 20px;
  z-index: 2;

  svg {
    width: 18px;
    height: 18px;
  }

  @include l670 {
    right: 12px;
    top: 12px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}
