.withdraw-limitation {
  &__content {
    margin-top: 20px;

    .open-profile {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__container {
      button {
        background-color: transparent;
        color: var(--green);
        text-decoration: underline;
        display: flex;
        margin: auto;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__text {
      display: flex;
      gap: 12px;

      &__p {
        margin-top: 4px;
        display: flex;
        gap: 4px;

        span {
          font-size: 13px;
        }
      }
    }

    &__img {
      width: 80px;
      height: 80px;
    }

    p {
      font-size: 18px;
      color: var(--landing-text-label);
    }
    button {
      font-size: 20px;
    }
  }
}
