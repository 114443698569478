@import "../../../style/mixin";

#root .static-bonus__bonuses .bonus__info {
  transition: 0.5s;
}

.static-bonus {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 20px;

    svg path {
      fill: var(--green-3);
    }
  }

  &__bonuses {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;

    .bonus {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 16px;
      overflow: hidden;
      background-color: darkturquoise;
      position: relative;
      padding: 16px;
      height: 430px;

      h4 {
        font-size: 24px;
        font-weight: 900;
        width: 90%;
        position: relative;
        z-index: 3;
        text-shadow: var(--black) 1px 0 10px;
      }

      &__light {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      img {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 15%;
        max-height: 300px;
        object-fit: contain;
      }

      &__terms {
        position: absolute;
        bottom: 20px;
        width: 80%;
        left: 10%;
        box-shadow: 3px 3px 5px #0000003c;
      }

      &.first-deposit {
        background: linear-gradient(var(--primary-select-bg), var(--bonus-first-bg));

        .bonus__light {
          background: var(--bonus-first-bg-tr);

          svg path {
            fill: var(--bonus-first-btn);
          }
        }

        .bonus__terms {
          background-color: var(--bonus-first-btn);
        }
      }

      &.caskback {
        background: linear-gradient(var(--primary-select-bg), var(--bonus-caskback-bg));

        .bonus__light {
          background: var(--bonus-caskback-bg-tr);
          svg path {
            fill: var(--bonus-caskback-btn);
          }
        }

        .bonus__terms {
          background-color: var(--bonus-caskback-btn);
        }
      }

      &.register {
        background: linear-gradient(var(--primary-select-bg), var(--bonus-register-bg));

        .bonus__light {
          background: var(--bonus-register-bg-tr);
          svg path {
            fill: var(--bonus-register-btn);
          }
        }

        .bonus__terms {
          background-color: var(--bonus-register-btn);
          span {
            color: var(--modal-bg);
          }
        }
      }

      &.promo {
        background: linear-gradient(var(--primary-select-bg), var(--bonus-promo-bg));

        .bonus__light {
          background: var(--bonus-promo-bg-tr);
          svg path {
            fill: var(--bonus-promo-btn);
          }
        }

        .bonus__terms {
          background-color: var(--bonus-promo-btn);

          span {
            color: var(--modal-bg);
          }
        }
      }
    }

    .bonus__info {
      z-index: 9;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--shade07);
      backdrop-filter: blur(10px);
      padding: 20px 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__btn-container {
        display: flex;
        justify-content: center;
      }

      .back-row {
        margin-top: 0;
      }

      h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: var(--white);
      }

      p {
        color: var(--shade7);
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 4px;
        }
      }

      &__btn-container,
      button,
      a {
        margin-top: auto;
        width: 100%;
        color: var(--white);
        font-weight: 600;
        text-align: center;
      }

      &.hidden-bonus {
        opacity: 0;
        z-index: -1;
      }
    }
  }

  @include lsm {
    &__bonuses {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }

  @include ltb {
    &__bonuses {
      gap: 12px;

      .bonus__info p {
        font-size: 16px;
      }
    }
  }

  @include lxs {
    &__bonuses {
      grid-template-columns: 1fr;
    }
  }
}
