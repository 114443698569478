@import "../../style/mixin";

.banner {
  &-page__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 12px;

    .back-row {
      margin-top: 0;
    }

    h2 {
      margin-top: 32px;
      font-size: 28px;
      text-transform: uppercase;
    }

    .banner-item {
      max-width: 100%;
      padding: 0;
    }
  }

  &__full-text {
    font-size: 24px;
    font-weight: 600;
    color: var(--white-80);
  }

  @include l1440 {
    h2 {
      margin-top: 20px;
    }
  }

  @include lsm {
    h2 {
      margin-top: 4px;
      font-size: 24px;
    }

    &__full-text {
      font-size: 18px;
    }
  }

  @include l670 {
    h2 {
      font-size: 20px;
    }

    &__full-text {
      font-size: 16px;
    }
  }
}
