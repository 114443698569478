@import "../../../../style/mixin";

.withdraw-success {
  h2 {
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;

    .accent {
      font-size: 26px;
      font-weight: 600;
    }
  }

  &__items {
    margin-top: 52px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__info {
    margin-top: 52px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    p {
      color: var(--landing-text-label);
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
    }
  }

  &__support {
    font-weight: 600;
    color: var(--telegram);
  }
}

.withdraw-error {
  &__p {
    margin-top: 52px;
    color: var(--red);
    font-size: 20px;
    line-height: 24px;
  }

  @include l670 {
    h2 {
      font-size: 2rem;

      .accent {
        font-size: 2rem;
      }
    }

    &__items {
      margin-top: 4rem;
      gap: 1rem;
    }

    &__info {
      margin-top: 4rem;
      gap: 1.6rem;
      p {
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }

  .withdraw-error {
    &__p {
      margin-top: 4rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
