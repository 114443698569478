.game {
  background-color: var(--landing-background);
  min-height: 100svh;
  width: 100%;

  &__close-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    line-height: 40px;
    background-color: var(--landing-item-bg);
    border-radius: 8px;
  }

  &-iframe {
    width: 100svw;
    height: 100svh;
    border: none;
  }
}
