.payment-detail {
  &.mini h2 {
    font-size: 20px;
    text-align: center;
    width: calc(100% - 28px);
  }

  &__content {
    margin-top: 20px;
  }

  .descriptions {
    color: var(--shade7);
    font-size: 14px;
    font-weight: 600;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 16px;
    padding: 12px;
    background-color: var(--shade4);
    border-radius: 12px;

    &__field {
      display: flex;
      justify-content: space-between;
      gap: 4px;

      .field__label {
        color: var(--gray2);
        font-size: 14px;
        font-weight: 500;
      }

      .field__value {
        span {
          font-weight: 500;
          color: var(--white);
        }
      }

      .text-after {
        color: var(--gray2);
      }
    }
  }

  .warning-info {
    margin-top: 28px;
  }
}
