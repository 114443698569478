.error-modal {
  &__content {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 18px;
    }
  }

  &__refresh {
    display: flex;
    justify-content: center;
    color: var(--red);
    margin-top: 20px;
    font-weight: 700;
    cursor: pointer;
  }
}
