.top-up-balance {
  &__select {
    padding: 2px;
    border-radius: 12px;
    background: linear-gradient(90deg, var(--green-3), transparent);

    .top-up-select {
      &__currency {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 8px;
        border-radius: 10px;
        background-color: var(--primary-search-bg);
      }

      &__value {
        font-size: 16px;
        font-weight: 600;
      }

      &__content-container {
        background: var(--shade1);
        border-radius: 12px;
      }

      &__change {
        background-color: transparent;
        text-decoration: underline;
        color: var(--landing-text-label);

        &:hover {
          text-decoration: none;
        }
      }

      &__content {
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        background: var(--shade07);
        padding: 0 12px;
        height: 52px;
        gap: 8px;
      }

      &__gradient {
        position: absolute;
        background: radial-gradient(var(--top-up-gradient), transparent);
        filter: blur(20px);
        bottom: -50%;
        left: -40px;
        width: 80px;
        height: 100%;
      }
    }
  }
}
