@import "../../../../style/mixin";

.header__auth {
  display: flex;
  align-items: center;
  gap: 8px;

  .online__indicator {
    margin-right: 32px;
  }

  &__auth {
    display: flex;
    gap: 6px;
  }

  .choose-currency__wrapper {
    padding: 2px;
  }
  .choose-currency,
  .choose-currency__wrapper {
    border-radius: 12px 0 0 12px;
  }

  .plus,
  .minus,
  .promo {
    padding: 12px 16px;
    height: 48px;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .plus {
    border-radius: 0;
  }

  .minus {
    border-radius: 0 12px 12px 0;
  }

  @include l1580 {
    .online__indicator {
      margin-right: 20px;
    }

    .avatar-popup__person {
      max-width: 160px;
      width: auto;
    }
  }

  @include l1440 {
    gap: 4px;
    &__auth {
      gap: 4px;
    }

    .online__indicator {
      margin-right: 8px;

      &__count {
        font-size: 12px;
      }
    }

    .plus,
    .minus,
    .promo {
      padding: 8px 12px;
      height: 40px;

      svg {
        width: 14px;
        height: 14px;
      }

      span {
        font-size: 12px;
      }
    }

    .avatar-popup {
      &__person {
        height: 40px;
        border-radius: 10px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      &__popup {
        top: 48px;

        li {
          height: 40px;
        }
      }
    }

    .choose-currency {
      min-width: 110px;
      margin: 0;

      .currency {
        padding: 0;
        background-color: transparent;
      }

      &__plug ul {
        margin-top: 44px;
      }

      &,
      &__wrapper {
        border-radius: 10px 0 0 10px;
      }
    }
  }

  @include lsm {
    button {
      // padding: 4px 16px;

      span {
        font-size: 12px;
      }
    }
  }
}
