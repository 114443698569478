@import "./template.scss";
@import "./mixin";
@import "../components/mui/custom-segment.scss";

$scrollbarWidth: 12px;

@include l1220 {
  $scrollbarWidth: 10px;
}
@include lsm {
  $scrollbarWidth: 8px;
}

// preloader
body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1; // hide images
  content: url(../components/modal/fortune_modal/img/fortune-bg.webp)
    url(../components/modal/fortune_modal/img/fortune-bg.webp); // load images
}

html {
  background-color: var(--landing-background);
}

#root * {
  transition: 0.2s;
}

#container-widget {
  display: none;
}

#root #container-widget {
  display: block;
  // overflow: auto;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Fixel Display";
  box-sizing: border-box;
  font-weight: 400;
  font-style: normal;
  color: var(--white);
  font-size: 14px;
  // @include b4k {
  //   font-size: 28px;
  // }

  // @include sm {
  //   font-size: 1.4rem;
  // }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
}

*::-webkit-scrollbar {
  width: $scrollbarWidth;
}

*::-webkit-scrollbar-track {
  background: var(--landing-item-bg);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--landing-item-bg-2);
  border-radius: 20px;
  border: 3px solid var(--landing-item-bg-2);
  cursor: pointer;
}

.ReactModal__Overlay {
  z-index: 1200;
  overflow: auto;
  background-color: #000000aa !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.no-scroll {
  overflow: hidden;
}

body {
  max-width: 100%;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

i {
  font-style: italic;
}

button {
  outline: none;
  border: none;
  text-align: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}

a {
  color: var(--landing-text-label);
  text-decoration: none;

  &:hover {
    color: var(--landing-text-label-hover);
  }
}

.hidden {
  display: none;
}

.rotate0 {
  transform: rotate(0deg);
}
.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}

.text-error {
  color: var(--red);
  padding-top: 12px;
}
.text-success {
  color: var(--green);
  padding-top: 12px;
}

.cus-checkbox {
  cursor: pointer;
}

@include sm {
  html {
    font-size: 2.55vw; // 10px on 320px
  }
}

// @include b2k {
//   html {
//     font-size: 0.520833vw; // 10px on 1920px
//   }
// }

// range

.MuiSlider-track,
.MuiSlider-rail {
  color: var(--primary);
}
.MuiSlider-thumb {
  color: var(--primary);
  &:hover {
    box-shadow: 0px 0px 0px 8px rgba(118, 18, 25, 0.16) !important;
  }
}

.MuiSlider-valueLabelOpen {
  background-color: transparent !important;
}

// picker

.ant-picker-dropdown {
  z-index: 9999;
}

.required {
  color: var(--red);
}

.ReactModal__Content:focus-visible,
textarea {
  border: none;
  outline: none;
}

.body__loader-overlay {
  overflow: hidden;
}

.Toastify {
  font-size: 16px;

  @include b4k {
    font-size: 3.2px;

    &__toast {
      padding: 20px;

      button svg {
        width: 32px;
        height: 32px;
      }
    }

    &__toast-container {
      width: 640px;
    }

    &__toast-icon {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  // @include sm {
  //   font-size: 1.6rem;
  // }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.hide {
  position: absolute;
  opacity: 0;
  z-index: -10;
}

.bg-blur {
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(20px);
  overflow: hidden;
}

button[data-fancybox-toggle-fullscreen] {
  display: none;
}

img {
  user-select: none;
}

@include b4k {
  .spinner-container h3 {
    font-size: 36px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and ((max-device-width: 670px) and (max-width: 670px)) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}

// comment

// input[type="radio"]:after {
//   width: 13px;
//   height: 13px;
//   border-radius: 50%;
//   top: -2px;
//   left: -1px;
//   position: relative;
//   background-color: var(--bg-dark-gray);
//   content: "";
//   display: inline-block;
//   visibility: visible;
//   border: 2px solid white;
// }
// input[type="radio"]:checked:after {
//   width: 13px;
//   height: 13px;
//   border-radius: 50%;
//   top: -2px;
//   left: -1px;
//   position: relative;
//   background-color: var(--red);
//   content: "";
//   display: inline-block;
//   visibility: visible;
//   border: 2px solid white;
// }

//carousel
// .ant-carousel .slick-dots li {
//   width: 8px;
//   &.slick-active {
//     width: 8px;
//     height: 8px;
//     button {
//       background-color: var(--red);
//     }
//   }

//   button {
//     border-radius: 50%;
//     width: 8px;
//     height: 8px;
//     background: rgba(255, 255, 255, 0.1);
//   }
// }

// available

// button:focus {
//   box-shadow: var(--box-shadow-focus);
// }

// label:has(> input[type="radio"]:focus) {
//   box-shadow: var(--box-shadow-focus);
// }

.owl-dots {
  text-align: center;
  position: absolute;
  width: 100%;

  .owl-dot {
    display: inline-block;
    margin: 5px;
    span {
      width: 10px;
      height: 10px;
      background: #cccccc77;
      display: block;
      border-radius: 50%;
      transition: background 0.3s;
    }
    &.active span {
      background: #ccc;
    }
  }
}
