.bonus-menu__img__container {
  display: flex;
  justify-content: center;
  img {
    object-fit: contain;
    max-height: 300px;
  }
}

.bonus-menu {
  padding: 12px;
  background: url("./present-bg.png") no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;

  &__img {
    width: 100%;
  }

  img {
    position: absolute;
    bottom: -40%;
    right: -15%;
  }

  h2 {
    font-size: 18px;
    width: 80%;
  }

  button {
    margin-top: 20px;
    padding: 12px 20px;
    min-height: 40px;

    span {
      font-size: 14px;
    }
  }

  &__gradient {
    position: absolute;
    z-index: 1;
    width: 200px;
    height: 40px;
    right: -20%;
    bottom: -10%;
    filter: blur(10px);
    background: rgb(7, 98, 255);
  }
}
