.profile-modal {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .already-text {
    margin-top: 20px;
    border: 4px solid var(--green);
    padding: 8px;
    border-radius: 12px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      color: var(--landing-text-label-2);
    }

    button {
      width: 100%;
    }

    .captcha {
      margin-top: 12px;
    }

    .buttons {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
