@import "../../../../style/_mixin.scss";

.online__indicator {
  display: flex;
  align-items: center;
  gap: 4px;

  &__text {
    font-weight: 700;
    display: none;
  }

  &__circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--indicator-bg);
  }

  &__count {
    font-weight: 700;
    font-size: 14px;
    color: var(--landing-text-label);
  }

  @include b1580 {
    &__text {
      display: block;
    }
  }
}
